import { useState } from 'react';
import { urls, route, startUrl } from './route';
import "./App.css"
import { useLoadData, useTitle } from './utils';
import { Navigate, Route, useNavigate, Routes } from 'react-router-dom';
import { headers, headersV2, localStorageUserKey } from './services/config';
import { getApiHealth } from './services/health';
import { Sidebar } from './components/Sidebar/Sidebar';
import { LoginPage } from './pages/login';
import { Grid } from '@mui/material';
import { AssessementList } from './pages/assessement-list';
import { ExercisesList } from './pages/exercises-list'
import { ExercisesEdit } from './pages/exercises-edit'
import { EquipmentList } from './pages/equipment-list';
import { AssessementEdit } from './pages/asessement-edit';
import { EquipmentEdit } from './pages/equipment-edit';
import { RoutinesList } from './pages/routines-list';
import { RoutinesEdit } from './pages/routines-edit';
import { RoutinesTypesList } from './pages/routines-types-list';
import { RoutinesTypesEdit } from './pages/routines-types-edit';
import { JointsList } from './pages/joints-list';
import { JointEdit } from './pages/joints-edit';
import { UserList } from './pages/show-users';
import { ExerciseTypesList } from './pages/single-exercise-type-list';
import { ExerciseTypesEdit } from './pages/single-exercise-type-edit';
import { ProgramsList } from './pages/programs-list';
import { ProgramsEdit } from './pages/programs-edit';
import { ProgramsTypesList } from './pages/program-types-list';
import { ProgramsTypesEdit } from './pages/programs-types-edit';
import { UserEdit } from './pages/users-edit';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { PhaseEdit } from './pages/phase-edit';
import PainkillersList from './pages/painkillers';

const App = () => {
  useTitle("ROM Coach");
  const history = useNavigate();
  const [isUserChecked, setIsUserChecked] = useState(false);
  const savedUser = window.localStorage.getItem(localStorageUserKey);

  useLoadData(async () => {
    if (!savedUser) {
      history(route(urls.login));
    } else {
      const user: {
        username: string;
        token: string;
      } = JSON.parse(savedUser);
      Object.assign(headers, { Authorization: `${user.token}` });
      Object.assign(headersV2, { Authorization: `Bearer ${user.token}` });
      try {
        await getApiHealth();
      } catch (e) {
        delete headers.Authorization;
        history(route(urls.login));
      }
    }
    setIsUserChecked(true);
  });

  if (!isUserChecked) return <div></div>;
  return (
    <div>
      {!savedUser ?
        <Routes>
          <Route
            path={route(urls.login)}
            element={<LoginPage />}
          />
          <Route path="/" element={<Navigate to={startUrl()} />} />
        </Routes> :
        <Grid item container alignItems="stretch" direction="column">
          <Sidebar>
            <Routes>
              <Route path="/app">
                <Route
                  path={route(urls.assessementList)}
                  element={<AssessementList />}
                />
                <Route
                  path={route(urls.equipmentList)}
                  element={<EquipmentList />}
                />
                <Route
                  path={route(urls.exercisesList)}
                  element={<ExercisesList />}
                />
                <Route
                  path={route(urls.jointsList)}
                  element={<JointsList />}
                />
                <Route
                  path={route(urls.jointsAdd)}
                  element={<JointEdit />}
                />
                <Route
                  path={route(urls.jointsEdit)}
                  element={<JointEdit />}
                />
                <Route
                  path={route(urls.equipmentEdit)}
                  element={<EquipmentEdit />}
                />
                <Route
                  path={route(urls.equipmentAdd)}
                  element={<EquipmentEdit />}
                />
                <Route
                  path={route(urls.assessementAdd)}
                  element={<AssessementEdit />}
                />
                <Route
                  path={route(urls.assessementEdit)}
                  element={<AssessementEdit />}
                />
                <Route
                  path={route(urls.exercisesEdit)}
                  element={<ExercisesEdit />}
                />
                <Route
                  path={route(urls.exercisesAdd)}
                  element={<ExercisesEdit />}
                />
                <Route
                  path={route(urls.routinesList)}
                  element={<RoutinesList />}
                />
                <Route
                  path={route(urls.routinesAdd)}
                  element={<RoutinesEdit />}
                />
                <Route
                  path={route(urls.routinesEdit)}
                  element={<RoutinesEdit />}
                />
                <Route
                  path={route(urls.routinesTypeList)}
                  element={<RoutinesTypesList />}
                />
                <Route
                  path={route(urls.routinesTypeEdit)}
                  element={<RoutinesTypesEdit />}
                />
                <Route
                  path={route(urls.routineTypeAdd)}
                  element={<RoutinesTypesEdit />}
                />
                <Route
                  path={route(urls.exerciseTypeList)}
                  element={<ExerciseTypesList />}
                />
                <Route
                  path={route(urls.exerciseTypeEdit)}
                  element={<ExerciseTypesEdit />}
                />
                <Route
                  path={route(urls.exerciseTypeAdd)}
                  element={<ExerciseTypesEdit />}
                />
                <Route
                  path={route(urls.userList)}
                  element={<UserList />}
                />
                <Route
                  path={route(urls.userEdit)}
                  element={<UserEdit />}
                />
                <Route
                  path={route(urls.programsList)}
                  element={<ProgramsList />}
                />
                <Route
                  path={route(urls.programsAdd)}
                  element={<ProgramsEdit />}
                />
                <Route
                  path={route(urls.programsEdit)}
                  element={<ProgramsEdit />}

                />
                <Route
                  path={route(urls.programsTypeList)}
                  element={<ProgramsTypesList />}
                />
                <Route
                  path={route(urls.programsTypeEdit)}
                  element={<ProgramsTypesEdit />}
                />
                <Route
                  path={route(urls.programsTypeAdd)}
                  element={<ProgramsTypesEdit />}
                />
                <Route
                  path={route(urls.phaseAdd)}
                  element={<PhaseEdit />}
                />
                <Route
                  path={route(urls.phaseEdit)}
                  element={<PhaseEdit />}
                />
                <Route
                  path={route(urls.painkillersList)}
                  element={<PainkillersList />}
                />
              </Route>
              <Route path="/" element={<Navigate to={startUrl()} />} />
              <Route path="/login" element={<Navigate to={startUrl()} />} />
            </Routes>
          </Sidebar>
          <ToastContainer />
        </Grid>
      }
    </div >
  );
}
export default App;
