import React, { useState } from 'react';
import { List, ListItem, SvgIconTypeMap } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useLocation } from 'react-router-dom';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export type UrlType = {
  fn: ((p?: any) => string),
  itemText: string,
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; },
  customIcon?: string,
  view: boolean
}

export interface SidebarRouter {
  url: UrlType[],
}

export default function SidebarNav(p: SidebarRouter) {
  const history = useNavigate();
  const location = useLocation();
  const { url } = p;
  const [selectedIndex, setSelectedIndex] = useState(url.map(e => e.fn()).indexOf(location.pathname) + 1);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };
  return <List>
    {url.map((Route, i) =>
      <div key={i}>{
        Route.view && <ListItem button
                                selected={selectedIndex === i + 1}
                                key={i + Route.itemText}
                                onClick={(e) => {
                                  handleListItemClick(e, i + 1);
                                  history(Route.fn())
                                }}>
              <ListItemIcon>
                {Route.customIcon?.length ?
                  <img src={Route.customIcon} alt={Route.customIcon} style={{ width: 24, height: 24 }}/>
                  : <Route.icon/>}
              </ListItemIcon>
              <ListItemText primary={Route.itemText}/>
          </ListItem>
      }</div>
    )}
  </List>
};
