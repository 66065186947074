import { baseUrlV2, headers, headersV2 } from './config';
import { fetchAndParseV2Paginated } from './utils';
import { PainkillerEvaluation } from '../models/PainkillerEvaluation';
import { QueryResult } from '@material-table/core';
import { parseSearchParams } from '../utils';

export interface PainkillerEvaluations {
  items: PainkillerEvaluation[]
}

export async function getApiPainkillersEvaluationsList(query: any): Promise<QueryResult<PainkillerEvaluation>> {
  let url = new URL(`/user/painkiller-evaluation/admin`, baseUrlV2);
  const queryParams = parseSearchParams(query);

  const data = await fetchAndParseV2Paginated<PainkillerEvaluation>(url.toString() + '?' + queryParams, { method: "GET", headers: headersV2 });
  return { data: data.rows, totalCount: data.count, page: 1 };
}
