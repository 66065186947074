import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MenuProps } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import { urls, route } from '../../route';
import { useNavigate } from 'react-router';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DirectionsRunSharpIcon from '@mui/icons-material/DirectionsRunSharp';
import SidebarNav, { SidebarRouter, UrlType } from './SidebarNav';
import { localStorageUserKey, version } from '../../services/config';
import logo from './rom-coach-logo-web.png';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
const StyledMenuItem = withStyles(() => ({
}))(MenuItem);

// const logo = require('./rom-coach-logo-web.png');

const drawerWidth = 220;
const useStyles = makeStyles((theme: { mixins: { toolbar: any; }; transitions: { create: (arg0: string, arg1: { easing: any; duration: any; }) => any; easing: { sharp: any; }; duration: { enteringScreen: any; leavingScreen: any; }; }; spacing: (arg0: number) => any; breakpoints: { up: (arg0: string) => any; }; }) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        marginLeft: 230,
    },
    drawerPaper: {
        overflowX: 'hidden',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: {
        flexGrow: 1,
    },
}));
type Props = PropsWithChildren<{}>;
export const Sidebar = (p: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const history = useNavigate();
    const classes = useStyles();
    const [url, setUrl] = React.useState<SidebarRouter["url"]>([
        {
            fn: urls.userList,
            itemText: "Users",
            icon: PersonIcon,
            view: true,
        }, {
            fn: urls.assessementList,
            itemText: "Assessement",
            icon: AssessmentIcon,
            view: true,
        }, {
            fn: urls.equipmentList,
            itemText: "Equipment",
            icon: SportsTennisIcon,
            view: true,
        }, {
            fn: urls.jointsList,
            itemText: "Joints",
            icon: AccessibilityIcon,
            view: true,
        }, {
            fn: urls.exercisesList,
            itemText: "Single Exercises",
            icon: DirectionsRunSharpIcon,
            view: true,
        }, {
            fn: urls.exerciseTypeList,
            itemText: "Exercise Types",
            icon: FitnessCenterIcon,
            view: true,
        }, {
            fn: urls.routinesList,
            itemText: "Routines",
            icon: ThreeSixtyIcon,
            view: true,
        }, {
            fn: urls.routinesTypeList,
            itemText: "Routines Types",
            icon: FitnessCenterIcon,
            view: true,
        },
        {
            fn: urls.programsList,
            itemText: "Programs",
            icon: ThreeSixtyIcon,
            view: true,
        }, {
            fn: urls.programsTypeList,
            itemText: "Programs Types",
            icon: FitnessCenterIcon,
            view: true,
        }, {
            fn: urls.painkillersList,
            itemText: "Painkiller Eval.",
            icon: AssessmentIcon,
            customIcon: '/shooting-target.webp',
            view: true,
        },
    ]);
    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [moreEl, setMoreEl] = React.useState<null | HTMLElement>(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        if (open)
            setOpen(false);
        else
            handleDrawerOpen();
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleHide = (e: UrlType) => {
        const newUrl = url.map(e => e)
        newUrl[newUrl.map(e => e.itemText).indexOf(e.itemText)].view = !e.view
        setUrl(newUrl)
    }
    const handleLogOut = () => {
        localStorage.removeItem(localStorageUserKey)
        history(route(urls.login))
    }
    return <div className={classes.root}>
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                <div className="sidebarLogoWrapper">
                    <img className="sidebarLogo" src={String(logo)} alt="logo" />
                </div>
                <IconButton size="small" onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <div>
                <IconButton size="small" style={{ marginLeft: "14px", marginTop: "8px", marginBottom: "4px" }} onClick={(event) => { setMoreEl(event.currentTarget) }}>
                    <MoreHorizIcon />
                </IconButton>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={moreEl}
                    keepMounted
                    open={Boolean(moreEl)}
                    onClose={() => { setMoreEl(null) }}>
                    <Button variant="contained" style={{ width: "8em", height: "2em", marginLeft: "1em", marginRight: "0.5em" }} onClick={handleLogOut}>Log Out</Button>
                    <IconButton
                        onClick={handleClick}
                    >
                        {url.some(e => !e.view) ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {url.map(e => <StyledMenuItem key={e.itemText}>
                            <ListItemText primary={e.itemText} />
                            <Checkbox value={e.view} onClick={() => handleHide(e)} />
                        </StyledMenuItem>
                        )}
                    </StyledMenu>
                </StyledMenu>
            </div>
            <SidebarNav
                url={url}
            />
            <div style={{ display: "flex", justifyContent: "center", marginTop: "auto", marginBottom: "20px" }}> Web UI v.{version} </div>
        </Drawer>
        <div className={classes.content}>
            <div className="mainAppWrapper">

                <Container maxWidth="lg" className={classes.container}>
                    {p.children!}
                </Container>
            </div>
        </div>
    </div>
}
