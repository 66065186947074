import { useRef, useState } from 'react'
import DataGrid, { Options } from '@material-table/core';
import { UserProfilePayload } from '../models/UserProfilePayload';
import { loadTableData } from './loaders';
import { getApiUserList } from '../services/user';
import moment from 'moment'
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { useNavigate } from 'react-router-dom';
import ReactExport from "react-export-excel";
import icon from "../export-excel.png";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const parseLicense = (productId?: string) => {
    switch (productId) {
        case 'Stripe Monthly':
            return {
                platform: 'Web',
                type: 'Monthly'
            }
        case 'Stripe Annual':
            return {
                platform: 'Web',
                type: 'Annual'
            }
        case 'Google Monthly':
            return {
                platform: 'Google',
                type: 'Monthly'
            }
        case 'Google Annual':
            return {
                platform: 'Google',
                type: 'Annual'
            }
        case 'Apple Monthly':
            return {
                platform: 'Apple',
                type: 'Monthly'
            }
        case 'Apple Annual':
            return {
                platform: 'Apple',
                type: 'Annual'
            }
        case 'Custom':
            return {
                platform: '',
                type: 'Custom'
            }
        default:
            return {
                platform: '',
                type: ''
            }
    }
}

const download = async () => {
    const data = await getApiUserList()


    const excelFile = new ExcelFile({
        filename: "Users",
        fileExtension: "xlsx",
        children: [<ExcelSheet data={data.items.map(user => {
          const licenseData = parseLicense(user.subscription?.product_id);
          return {
            firstName: user.profile?.firstName,
            lastName: user.profile?.lastName,
            email: user.email,
            dob: user.profile?.birthDate ? moment(user.profile.birthDate).format('L') : null,
            movementAge: `${user.movementAge ? user.movementAge : "Not specified"} ${user.movementAge ? (moment().diff(user.profile?.birthDate, 'years') < user.movementAge ? `( +${user.movementAge - (moment().diff(user.profile?.birthDate, 'years'))} )` : `( -${(moment().diff(user.profile?.birthDate, 'years')) - user.movementAge} )`) : ""}`,
            gender: user.profile?.gender ? user.profile?.gender === 1 ? "Male" : user.profile?.gender === 2 ? "Female" : "Not Specified" : "Not Specified",
            accountType: user.authType,
            availability: user.active ? user.active.toString().charAt(0).toUpperCase() + user.active.toString().slice(1) : "",
            platform: licenseData.platform,
            licenseType: licenseData.type,
            licenceExpiration: user.licenceExpiration ? moment(user.licenceExpiration).format('L') : "Not specified"
        }
          }
        )} name="Users">
            <ExcelColumn label="First Name" value="firstName" />
            <ExcelColumn label="Last Name" value="lastName" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="DOB" value="dob" />
            <ExcelColumn label="Movement age" value="movementAge" />
            <ExcelColumn label="Gender" value="gender" />
            <ExcelColumn label="Account Type" value="accountType" />
            <ExcelColumn label="Availability" value="availability" />
            <ExcelColumn label="Platform" value="platform" />
            <ExcelColumn label="Licence Type" value="licenseType" />
            <ExcelColumn label="Licence expiration" value="licenceExpiration" />
        </ExcelSheet>]
    });
    excelFile.handleDownload();
};


export const UserList = () => {
    const history = useNavigate();
    const ref = useRef<any>();

    const [pagination,] = useState<Options<UserProfilePayload>>({
        actionsColumnIndex: -1,
        search: true,
        maxColumnSort: "all_columns",
        pageSize: 10,
        columnsButton: true
    });
    return <div>
        <DataGrid<UserProfilePayload>
            title="Users"
            options={pagination}
            tableRef={ref}
            columns={[
                { title: "First Name", render: (e: UserProfilePayload) => <>{e.profile?.firstName}</>, cellStyle: { width: "15%" } },
                { title: "Last Name", render: (e: UserProfilePayload) => <>{e.profile?.lastName}</>, cellStyle: { width: "15%" } },
                { title: "Email", field: "email", cellStyle: { width: "20%" } },
                { title: "DOB", render: (e: UserProfilePayload) => <>{e.profile?.birthDate ? moment(e.profile.birthDate).format('L') : null}</>, cellStyle: { width: "8%" } },
                { title: "Movement Age", render: (e: UserProfilePayload) => <>{`${e.movementAge ? e.movementAge : "Not specified"} ${e.movementAge ? (moment().diff(e.profile?.birthDate, 'years') < e.movementAge ? `( +${e.movementAge - (moment().diff(e.profile?.birthDate, 'years'))} )` : `( -${(moment().diff(e.profile?.birthDate, 'years')) - e.movementAge} )`) : ""}`}</>, cellStyle: { width: "8%" } },
                { title: "Gender", render: (e: UserProfilePayload) => <>{e.profile?.gender ? e.profile.gender === 1 ? "Male" : e.profile.gender === 2 ? "Female" : "Not Specified" : "Not Specified"}</>, cellStyle: { width: "5%" } },
                { title: "Account Type", field: "authType", cellStyle: { width: "8%" } },
                { title: "Availability", render: (e: UserProfilePayload) => <>{e.active ? e.active.toString().charAt(0).toUpperCase() + e.active.toString().slice(1) : ""}</>, cellStyle: { width: "5%" } },
                { title: "Licence Expiration", render: (e: UserProfilePayload) => <>{e.licenceExpiration ? moment(e.licenceExpiration).format('L') : "Not specified"}</>, cellStyle: { width: "10%" } },
                {
                    title: 'Tools',
                    render: (e) => (
                        <>
                            {/* <IconButton onClick={userConfirmDelete(e, deleteApiUsersUserid, ref)} style={{ float: "right" }}><Icon>delete</Icon></IconButton> */}
                            <IconButton onClick={() => history(`/app/users/edit/${e.id}`)} style={{ float: "right" }}><Icon>edit</Icon></IconButton>
                        </>
                    )
                }
            ]}
            data={loadTableData(getApiUserList)}
            actions={[{
                tooltip: "Export CSV",
                isFreeAction: true,
                disabled: false,
                icon: "export-csv",
                onClick: download
            }]}
            components={{
                Action: props => {
                    if (props.action.icon === "export-csv") {
                        return <IconButton onClick={props.action.onClick} size="small">
                            <img alt='export-excel' src={String(icon)} style={{
                                width: "24px",
                                height: "24px",
                            }} />
                        </IconButton>
                    }
                    else { return <></> }
                }
            }}
        />

    </div>
}
