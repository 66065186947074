import { useNavigate } from 'react-router';
import { useCallback, useEffect, useRef, useState } from 'react';
import MaterialTable, { Options } from '@material-table/core';
import { getApiPainkillersEvaluationsList } from '../services/painkiller';
import { PainkillerEvaluation } from '../models/PainkillerEvaluation';
import moment from 'moment/moment';
import { getApiUserList } from '../services/user';
import IconButton from '@mui/material/IconButton';
import icon from '../export-excel.png';
import ReactExport from 'react-export-excel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const PainkillersList = () => {
  const history = useNavigate();
  const ref = useRef<any>();
  const [pagination,] = useState<Options<PainkillerEvaluation>>({
    actionsColumnIndex: -1,
    selection: false,
    pageSize: 30,
    initialPage: 1,
    pageSizeOptions: [30, 50, 70, 100]
  });

  const [painkillers, setPainkillers] = useState<PainkillerEvaluation[]>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  useEffect(() => {
    const getData = async () => {

      let query: any = {}

      if (startDate) query.from = startDate;
      if (endDate) query.to = endDate;

      const data = await getApiPainkillersEvaluationsList(query);
      setPainkillers([...data.data])
    }

    getData()
  }, [startDate, endDate]);

  const download = useCallback(async () => {
    const excelFile = new ExcelFile({
      filename: "Painkillers",
      fileExtension: "xlsx",
      children: [<ExcelSheet data={painkillers.map(item => ({
        user: item.user?.email,
        area: item.area,
        startRating: item.startRating,
        endRating: item.endRating,
        wasShared: item.wasShared,
        date: moment(item.createdAt).format('L')
      }))} name="Users">
        <ExcelColumn label="User" value="user"/>
        <ExcelColumn label="Area" value="area"/>
        <ExcelColumn label="Pain before" value="startRating"/>
        <ExcelColumn label="Pain after" value="endRating"/>
        <ExcelColumn label="Was shared" value="wasShared"/>
        <ExcelColumn label="Date" value="date"/>
      </ExcelSheet>]
    });
    excelFile.handleDownload();
  }, [painkillers]);

  return <div>
    <Grid style={{marginBottom: 10}} container spacing={2}>
      <Grid item xs={0} sm={8}/>
      <Grid item xs={6} sm={2}>
        <TextField
          fullWidth
          type="date"
          label="from"
          value={startDate?.toString()}
          id={"startDate"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            setStartDate(event.target.value.toString())
          }}
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField
          fullWidth
          type="date"
          label="to"
          variant="outlined"
          value={endDate?.toString()}
          id={"endDate"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            setEndDate(event.target.value.toString())
          }}
        />
      </Grid>
    </Grid>
    <MaterialTable<PainkillerEvaluation>
      title="Painkiller evaluations"
      options={pagination}
      tableRef={ref}
      columns={[
        {
          title: "Date",
          render: (e: PainkillerEvaluation) => <>{e.createdAt ? moment(e.createdAt).format('L') : "Not specified"}</>,
          cellStyle: { width: "10%" }
        },
        { title: "User", field: "user.email", width: "40%" },
        { title: "Area", field: "area", width: "10%" },
        { title: "Pain before", field: "startRating", width: "10%" },
        { title: "Pain after", field: "endRating", width: "10%" },
        { title: "Was shared", field: "wasShared", width: "10%" },
      ]}
      data={painkillers}
      actions={[{
        tooltip: "Export CSV",
        isFreeAction: true,
        disabled: false,
        icon: "export-csv",
        onClick: download
      }]}
      components={{
        Action: props => {
          if (props.action.icon === "export-csv") {
            return <IconButton onClick={props.action.onClick} size="small">
              <img alt='export-excel' src={String(icon)} style={{
                width: "24px",
                height: "24px",
              }}/>
            </IconButton>
          } else {
            return <></>
          }
        }
      }}

    />
  </div>
}

export default PainkillersList;
